<template>
  <div>
    <v-container fluid>
      <breadcrumbs :breadcrumbs="breadcrumbs"/>
      <v-row>
        <v-col cols="3">
          <v-card class="rounded-lg shadow" flat>

            <v-list>
              <v-list-item-group v-model="model" mandatory>
                <v-list-item color="primary"
                             class="translateY nav-list"
                             active-class="border--primary"
                             v-for="(item, i) in items"
                             :key="i"
                             :value="item.value">
                  <v-list-item-icon>
                    <v-icon color="primary" v-text="item.icon" dense></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="$t(item.text)"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>

          </v-card>
        </v-col>

        <v-col cols="9">
          <ProductList v-show="model===1" ref="productList"/>
          <BrandList v-show="model===2" ref="brandList"/>
          <CategoryList v-show="model===3" ref="categoryList"/>
          <SubCategoryList v-show="model===4" ref="subCategoryList"/>
        </v-col>

      </v-row>
    </v-container>
  </div>
</template>

<script>


import BrandList from "@/views/products/brands/BrandList";
import CategoryList from "@/views/products/categories/CategoryList";
import SubCategoryList from "@/views/products/subCategories/SubCategoryList";
import ProductList from "@/views/products/list/ProductList";

export default {
  components: {ProductList, SubCategoryList, CategoryList, BrandList},
  data() {
    return {
      items: [
        {
          icon: 'mdi-cart',
          text: 'breadcrumb.products',
          value: 1,
        },
        {
          icon: 'mdi-tag-text',
          text: 'breadcrumb.brands',
          value: 2,
        },
        {
          icon: 'mdi-tray-full',
          text: 'breadcrumb.categories',
          value: 3,
        },
        {
          icon: 'mdi-tray-full',
          text: 'breadcrumb.subCategories',
          value: 4,
        },
      ],
      model: 1,
    }
  },
  watch: {
    model(val) {
      switch (val) {
        case 1:
          return this.$refs.productList.getItems()
        case 2:
          return this.$refs.brandList.getItems()
        case 3:
          return this.$refs.categoryList.getItems()
        case 4:
          return this.$refs.subCategoryList.getItems()
      }
    }
  },
  mounted() {
    this.$refs.productList.getItems()
  },
  computed: {
    breadcrumbs() {
      return [
        {to: '/products', text: 'breadcrumb.products'},
      ]
    },
  }
}
</script>

<style scoped>

</style>