<template>
  <div>
    <v-dialog v-model="dialog" max-width="500" scrollable>
      <form @submit.prevent="save">
        <v-card :loading="isLoading || fileLoading" :disabled="isLoading || fileLoading">
          <v-card-title>
            {{ $t(dialogTitle) }}
            <v-spacer/>
            <v-icon @click="close">mdi-close</v-icon>
          </v-card-title>
          <v-divider/>
          <v-card-text class="pa-4">

            <v-text-field :label="$t('field.code')"
                          :error-messages="errors.code"
                          class="required"
                          v-model="form.code">
            </v-text-field>

            <v-text-field :label="$t('field.name')"
                          :error-messages="errors.name"
                          class="required"
                          v-model="form.name">
            </v-text-field>

            <v-select :label="$t('field.brand')"
                      :error-messages="errors.brand_id"
                      class="required"
                      :items="$store.state.api.brands"
                      item-text="name"
                      item-value="id"
                      v-model="form.brand_id">
            </v-select>

            <v-select :label="$t('field.category')"
                      :error-messages="errors.category_id"
                      class="required"
                      :items="$store.state.api.categories"
                      item-text="name"
                      item-value="id"
                      v-model="form.category_id">
            </v-select>

            <v-select :label="$t('field.subCategory')"
                      :error-messages="errors.sub_category_id"
                      class="required"
                      :items="$store.state.api.subCategories"
                      item-text="name"
                      item-value="id"
                      v-model="form.sub_category_id">
            </v-select>

            <UploadFile @loading="fileLoading=$event"
                        :path="form.image"
                        type="image"
                        @file="form.image = $event"/>

            <v-textarea rows="1"
                        hide-details="auto"
                        :error-messages="errors.description"
                        :label="$t('field.description')"
                        v-model="form.description"/>
            <v-checkbox
                hide-details
                :label="$t('field.isActive')"
                v-model="form.is_active"
                color="blue"></v-checkbox>

          </v-card-text>
          <v-divider/>
          <v-card-actions>
            <v-spacer/>
            <v-btn color="primary"
                   :loading="isLoading"
                   type="submit"
                   depressed>
              <v-icon left>mdi-content-save</v-icon>
              {{ $t('btn.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  data: () => ({
    dialogTitle: null,
    fileLoading: false,
    isLoading: false,
    dialog: false,
    form: {},
    errors: {},
  }),
  methods: {
    open(item) {
      this.form = {
        image: null,
        is_active: true,
      }
      if (item) {
        this.dialogTitle = 'product.dialog.updateProduct'
        this.form = item
      } else {
        this.dialogTitle = 'product.dialog.addProduct'
      }
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    save() {
      this.errors = {}
      this.$Progress.start()
      this.isLoading = true

      let url = this.form.id ? "products/update" : "products/store"

      HTTP.post(url, this.form).then(() => {
        this.$emit('refresh')
        this.isLoading = false

        if (this.form.id) {
          this.$successMessage = this.$i18n.t('message.succssUpdate')
          this.close()
        } else {
          this.$successMessage = this.$i18n.t('message.succssStore')
          this.form = {
            image: null,
            is_active: true,
          }
        }
        this.$Progress.finish()
      }).catch((err) => {
        this.isLoading = false
        this.$Progress.fail()
        this.errors = err.response.data.errors
        this.$errorMessage = this.$i18n.t('message.catchError')
        console.log(err)
      })
    }
  }
}
</script>