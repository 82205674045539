<template>
  <div>
    <v-card class="rounded-lg shadow" flat :loading="isLoading || overlay" :disabled="isLoading || overlay">

      <FormDialog ref="formDialog" @refresh="getItems"/>

      <v-overlay :value="overlay"
                 absolute
                 :color="$vuetify.theme.dark ?'secondary':'white'">
        <v-progress-circular
            indeterminate
            size="60"
            color="primary"
        ></v-progress-circular>
      </v-overlay>

      <v-card-title>

        <v-text-field rounded filled dense
                      class="w-50"
                      v-model="search"
                      append-icon="mdi-magnify"
                      single-line hide-details clearable
                      :placeholder="$t('field.search')"/>

        <v-spacer/>

        <Refresh @refresh="getItems"/>

        <v-btn color="primary"
               rounded
               depressed
               @click="newItem">
          <v-icon left>mdi-plus</v-icon>
          {{ $t('btn.add') }}
        </v-btn>

        &nbsp;
        <ExcelStoreDialog item="products" @refresh="getItems"/>

      </v-card-title>

      <v-divider/>

      <v-card-text class="pa-0">

        <v-skeleton-loader v-if="isLoading" type="table"/>

        <div v-else>

          <v-data-table
              v-if="items.length"
              :items-per-page="5"
              :no-results-text="noResultsText"
              :footer-props="footerProps"
              :headers="headers"
              :items="items"
              :search="search"
          >

            <template v-slot:item.actions="{ item }">
              <div class="d-flex">

                <Delete url="products/delete"
                        :id="item.id"
                        @refresh="getItems"
                        @overlay="overlay = $event"/>

                <Update @update="updateItem(item)"/>

              </div>
            </template>

            <template v-slot:item.image="{ item }">

              <v-img v-if="item.image"
                     :src="$fileUrl + item.image"
                     width="40"
                     height="40"
                     class="zoom-pointer rounded"
                     @click="[image = [{src: $fileUrl + item.image}],visible = true]"/>

              <v-img v-else
                     :src="require('@/assets/no-image.png')"
                     width="40"
                     height="40"/>

            </template>

            <template v-slot:item.is_active="{ item }">

              <SwitchStatus @refresh="getItems"
                            @overlay="overlay = $event"
                            :id="item.id"
                            url="products/change-status"
                            :value="item.is_active"/>
            </template>

          </v-data-table>

          <NoResult v-else size="200"/>

        </div>

      </v-card-text>
    </v-card>


    <vue-easy-lightbox escDisabled
                       moveDisabled
                       :visible="visible"
                       :imgs="image"
                       :index="index"
                       @hide="visible = false"></vue-easy-lightbox>

  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import FormDialog from "@/views/products/list/FormDialog";

export default {
  components: {FormDialog},
  data() {
    return {
      overlay: false,
      isLoading: false,
      items: [],
      search: null,

      /**Lightbox**/
      image: '',
      visible: false,
      index: 0,
    }
  },
  methods: {
    getItems() {
      this.$Progress.start()
      this.isLoading = true
      HTTP.get('/products').then(res => {
        this.items = res.data.data
        let data = res.data.data.filter(el => el.is_active === 1)
        this.$store.commit('api/products', data)
        this.$Progress.finish()
        this.isLoading = false
      }).catch(err => {
        this.$Progress.fail()
        this.$errorMessage = this.$i18n.t('message.catchError')
        this.isLoading = false
        console.log(err)
      })
    },
    newItem() {
      this.$refs.formDialog.open()
    },
    updateItem(item) {
      this.$refs.formDialog.open(item)
    }
  },
  computed: {
    headers() {
      return [
        {text: this.$t('field.isActive'), value: 'is_active'},
        {text: this.$t('field.image'), value: 'image'},
        {text: this.$t('field.code'), value: 'code'},
        {text: this.$t('field.name'), value: 'name'},
        {text: this.$t('field.brands'), value: 'brand.name'},
        {text: this.$t('field.categories'), value: 'category.name'},
        {text: this.$t('field.subCategories'), value: 'sub_category.name'},
        {text: this.$t('field.actions'), value: 'actions', sortable: false},
      ]
    },
    footerProps() {
      return {
        itemsPerPageText: this.$t('message.itemsPerPageText'),
        itemsPerPageAllText: this.$t('message.all'),
        itemsPerPageOptions: [5, 10, 20, 30, 40, 50]
      }
    },
    noResultsText() {
      return this.$t('message.noResultsText')
    },
  }
}
</script>

<style scoped>

</style>